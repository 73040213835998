<template>
  <main id="users-teams-view">
    <div class="card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('users') }}</h4>
          </div>
          <div class="card__header-actions">
            <button class="btn" @click.prevent="showModal({ newItem: true, title: $t('new-user'), form: getNewUserModalForm() })">
              <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-user') }}
            </button>
          </div>
        </div>
        <div class="scrollable-cols">
        <table class="card__table">
          <colgroup>
            <col class="--w200px">
            <col class="--w200px">
            <col class="--w200px">
            <col class="--w100px">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('user') }}</th>
              <th>{{ $t('email') }}</th>
              <th>{{ $t('team') }}</th>
              <th class="--ta-r">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="index">
              <td :title="`${user.first_name} ${user.last_name}`">{{ `${user.first_name} ${user.last_name}` }}</td>
              <td :title="user.email">{{ user.email }}</td>
              <td :title="user.team ? user.team.name : 'NO TEAM'">{{ user.team ? user.team.name : $t('NO-TEAM') }}</td>
              <td class="--ta-r">
                <div class="table-cell-actions">
                  <button class="btn btn--hover" :title="$t('edit')" @click.prevent="showModal({ editItem: true, title: user.role === 'admin' ? $t('edit-admin') : $t('edit-user'), form: getEditUserForm(user) })">
                    <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('teams') }}</h4>
          </div>
          <div class="card__header-actions">
            <button class="btn" @click.prevent="showModal({ newItem: true, title: $t('new-team'), form: getNewTeamModalForm() })">
              <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-team') }}
            </button>
          </div>
        </div>
        <div class="scrollable-cols">
        <table class="card__table">
          <colgroup>
            <col class="--w200px">
            <col class="--w200px">
            <col class="--w100px">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('team') }}</th>
              <th>{{ $t('type') }}</th>
              <th class="--ta-r">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(team, index) in teams" :key="index">
              <td>{{ team.name }}</td>
              <td>{{ $t(team.role) }}</td>
              <td class="--ta-r">
                <div class="table-cell-actions">
                  <button class="btn btn--hover" :title="$t('edit')" @click.prevent="showModal({ editItem: true, title: $t('edit-team'), form: getEditTeamForm(team) })">
                    <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  GET_USER_DATA,
  GET_ACCOUNTS,
  GET_PERMISSIONS,
  GET_USERS,
  SAVE_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_TEAMS,
  SAVE_NEW_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_NOTIFICATION
} from '@/core/action-types'

const TEAM_ROLES = 'adops-team,commercial-team,billing-team'

export default {
  name: 'users-teams',
  data: function () {
    return {
      newUserModalForm: {
        fields: [{
          name: 'first_name',
          type: 'text',
          label: `${this.$t('first_name')} *`,
          title: this.$t('first_name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'last_name',
          type: 'text',
          label: `${this.$t('last_name')} *`,
          title: this.$t('last_name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'email',
          type: 'email',
          label: `${this.$t('email')} *`,
          title: this.$t('email'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'password',
          type: 'password',
          label: `${this.$t('password')} *`,
          title: this.$t('password'),
          value: '',
          required: true,
          maxlength: 20,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'team_id',
          type: 'select',
          label: this.$t('team'),
          title: this.$t('team'),
          dataList: [],
          value: '',
          required: false,
          class: '--w100'
        }],
        hideModal: this.hideModal,
        saveNewUser: this.saveNewUser,
        fetchUsers: this.fetchUsers,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save: function (query) {
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('user-created'), type: 'success', icon: 'success' })
            this.fetchUsers()
          }
          query.onComplete = onComplete.bind(this)
          this.saveNewUser(query)
          this.hideModal()
        }
      },
      editUserModalForm: {
        fields: [{
          name: 'first_name',
          type: 'text',
          label: `${this.$t('first_name')} *`,
          title: this.$t('first_name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'last_name',
          type: 'text',
          label: `${this.$t('last_name')} *`,
          title: this.$t('last_name'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'email',
          type: 'email',
          label: `${this.$t('email')} *`,
          title: this.$t('email'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'password',
          type: 'password',
          label: this.$t('password'),
          title: this.$t('password'),
          value: null,
          required: false,
          maxlength: 20,
          pattern: null,
          class: '--w50'
        },
        {
          name: 'team_id',
          type: 'select',
          label: this.$t('team'),
          title: this.$t('team'),
          dataList: [],
          value: '',
          required: false,
          class: '--w100'
        },
        {
          name: 'team_name',
          type: 'text',
          label: this.$t('team'),
          title: this.$t('team'),
          value: '',
          required: false,
          readonly: true,
          invisible: true,
          class: '--w100'
        }],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateUser: this.updateUser,
        fetchUsers: this.fetchUsers,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save: function (query) {
          if (this.originalData.first_name === query.first_name) {
            delete query.first_name
          }
          if (this.originalData.last_name === query.last_name) {
            delete query.last_name
          }
          if (this.originalData.email === query.email) {
            delete query.email
          }
          if (this.originalData.role === 'admin' || this.originalData.team_id === query.team_id) {
            delete query.team_id
          }
          if (query.password === null) {
            delete query.password
          }
          delete query.team_name
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('user-updated'), type: 'success', icon: 'success' })
            this.fetchUsers()
          }
          query.onComplete = onComplete.bind(this)
          this.updateUser(query)
          this.hideModal()
        },
        deleteUser: this.deleteUser,
        saveDelete: function (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-user'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('user-deleted'), type: 'success', icon: 'success' })
              this.fetchUsers()
            }
            query.onComplete = onComplete.bind(this)
            this.deleteUser(query)
            this.hideModal()
          }
        }
      },
      newTeamModalForm: {
        modalClass: 'card__modal--large',
        tabs: [
          {
            id: 'team',
            label: this.$t('team'),
            classSuffix: '--one'
          },
          {
            id: 'permissions',
            label: this.$t('permissions'),
            classSuffix: '--two'
          },
          {
            id: 'accounts',
            label: this.$t('accounts'),
            classSuffix: '--three'
          }
        ],
        fields: [
          {
            tab: 'team',
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            tab: 'team',
            name: 'role',
            type: 'radio',
            label: this.$t('role'),
            title: this.$t('role'),
            dataList: [
              {
                label: this.$t('adops-team'),
                value: 'adops-team'
              },
              {
                label: this.$t('commercial-team'),
                value: 'commercial-team'
              },
              {
                label: this.$t('billing-team'),
                value: 'billing-team'
              }
            ],
            value: 'adops-team',
            required: true,
            class: '--w100'
          },
          {
            tab: 'permissions',
            name: 'permissions',
            type: 'grouped-checkbox-list',
            title: this.$t('permissions'),
            dataList: [],
            value: [],
            required: false,
            class: '--w100'
          },
          {
            tab: 'accounts',
            name: 'facebook_account_ids',
            type: 'select',
            label: this.$t('facebook-accounts'),
            title: this.$t('facebook-accounts'),
            dataList: [],
            multiple: true,
            value: [],
            required: false,
            inputClass: 'input__select--multiple--large',
            class: '--w100'
          },
          {
            tab: 'accounts',
            name: 'twitter_account_ids',
            type: 'select',
            label: this.$t('twitter-accounts'),
            title: this.$t('twitter-accounts'),
            dataList: [],
            multiple: true,
            value: [],
            required: false,
            inputClass: 'input__select--multiple--large',
            class: '--w100'
          }
        ],
        hiddenFields: [
          {
            name: 'team_id',
            value: ''
          }
        ],
        hideModal: this.hideModal,
        saveNewTeam: this.saveNewTeam,
        fetchTeams: this.fetchTeams,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save: function (query) {
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('team-created'), type: 'success', icon: 'success' })
            this.fetchTeams({ roles: TEAM_ROLES })
          }
          query.onComplete = onComplete.bind(this)
          query.users = []
          query.accounts = []
          if (query.permissions.length > 0) {
            query.permissions = this.fields.find(field => field.name === 'permissions').dataList.filter(item => query.permissions.includes(item.value)).map(item => {
              return {
                name: item.label,
                guard_name: item.group,
                _id: item.value
              }
            })
          }
          this.saveNewTeam(query)
          this.hideModal()
        }
      },
      editTeamModalForm: {
        modalClass: 'card__modal--large',
        tabs: [
          {
            id: 'team',
            label: this.$t('team'),
            classSuffix: '--one'
          },
          {
            id: 'permissions',
            label: this.$t('permissions'),
            classSuffix: '--two'
          },
          {
            id: 'accounts',
            label: this.$t('accounts'),
            classSuffix: '--three'
          }
        ],
        fields: [
          {
            tab: 'team',
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            tab: 'team',
            name: 'role',
            type: 'radio',
            label: this.$t('role'),
            title: this.$t('role'),
            dataList: [
              {
                label: this.$t('adops-team'),
                value: 'adops-team'
              },
              {
                label: this.$t('commercial-team'),
                value: 'commercial-team'
              },
              {
                label: this.$t('billing-team'),
                value: 'billing-team'
              }
            ],
            value: 'adops-team',
            required: true,
            class: '--w100'
          },
          {
            tab: 'permissions',
            name: 'permissions',
            type: 'grouped-checkbox-list',
            title: this.$t('permissions'),
            dataList: [],
            value: [],
            required: false,
            class: '--w100'
          },
          {
            tab: 'accounts',
            name: 'facebook_account_ids',
            type: 'select',
            label: this.$t('facebook-accounts'),
            title: this.$t('facebook-accounts'),
            dataList: [],
            multiple: true,
            value: [],
            required: false,
            inputClass: 'input__select--multiple--large',
            class: '--w100'
          },
          {
            tab: 'accounts',
            name: 'twitter_account_ids',
            type: 'select',
            label: this.$t('twitter-accounts'),
            title: this.$t('twitter-accounts'),
            dataList: [],
            multiple: true,
            value: [],
            required: false,
            inputClass: 'input__select--multiple--large',
            class: '--w100'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          },
          {
            name: 'team_id',
            value: ''
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateTeam: this.updateTeam,
        fetchTeams: this.fetchTeams,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save: function (query) {
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('team-updated'), type: 'success', icon: 'success' })
            this.fetchTeams({ roles: TEAM_ROLES })
          }
          query.onComplete = onComplete.bind(this)
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.role === query.role) {
            delete query.role
          }
          if (this.originalData.team_id === query.team_id) {
            delete query.team_id
          }
          if (query.permissions.length > 0) {
            query.permissions = this.fields.find(field => field.name === 'permissions').dataList.filter(item => query.permissions.includes(item.value)).map(item => {
              return {
                name: item.label,
                guard_name: item.group,
                _id: item.value
              }
            })
          } else if (this.originalData.permissions === query.permissions) {
            delete query.permissions
          }
          this.updateTeam(query)
          this.hideModal()
        },
        deleteTeam: this.deleteTeam,
        saveDelete: function (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-team'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('team-deleted'), type: 'success', icon: 'success' })
              this.fetchTeams({ roles: TEAM_ROLES })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteTeam(query)
            this.hideModal()
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'users',
      'teams',
      'accounts',
      'permissions'
    ])
  },
  watch: {
    teams (newValue, oldValue) {
      const visibleTeams = [
        'adops-team',
        'commercial-team',
        'billing-team'
      ]
      const updatedDataList = newValue.filter(team => visibleTeams.includes(team.role)).map(team => {
        return {
          label: team.name,
          value: team._id
        }
      })
      this.newUserModalForm.fields[4].dataList = updatedDataList
      this.editUserModalForm.fields[4].dataList = updatedDataList
    }
  },
  mounted () {
    this.fetchData()
    this.fetchAccounts()
    this.fetchPermissions()
    this.fetchUsers()
    this.fetchTeams({ roles: TEAM_ROLES })
  },
  methods: {
    ...mapActions({
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      fetchData: GET_USER_DATA,
      fetchAccounts: GET_ACCOUNTS,
      fetchPermissions: GET_PERMISSIONS,
      fetchUsers: GET_USERS,
      saveNewUser: SAVE_NEW_USER,
      updateUser: UPDATE_USER,
      deleteUser: DELETE_USER,
      fetchTeams: GET_TEAMS,
      saveNewTeam: SAVE_NEW_TEAM,
      updateTeam: UPDATE_TEAM,
      deleteTeam: DELETE_TEAM,
      showNotification: SHOW_NOTIFICATION
    }),
    getNewUserModalForm () {
      let modalFormData = Object.assign({}, this.newUserModalForm)
      modalFormData.fields.find(item => item.name === 'first_name').value = ''
      modalFormData.fields.find(item => item.name === 'last_name').value = ''
      modalFormData.fields.find(item => item.name === 'email').value = ''
      modalFormData.fields.find(item => item.name === 'password').value = ''
      modalFormData.fields.find(item => item.name === 'team_id').dataList = [...this.teams.map(team => {
        return {
          value: team._id,
          label: team.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'team_id').value = ''
      return modalFormData
    },
    getEditUserForm (user) {
      let modalFormData = Object.assign({}, this.editUserModalForm)
      if (user.role === 'admin') {
        delete modalFormData.saveDelete
      }
      modalFormData.hiddenFields.find(item => item.name === 'id').value = user._id
      modalFormData.fields.find(item => item.name === 'first_name').value = user.first_name
      modalFormData.originalData.first_name = user.first_name
      modalFormData.fields.find(item => item.name === 'last_name').value = user.last_name
      modalFormData.originalData.last_name = user.last_name
      modalFormData.fields.find(item => item.name === 'email').value = user.email
      modalFormData.originalData.email = user.email
      if (user.role === 'admin') {
        modalFormData.fields.find(item => item.name === 'team_id').invisible = true
        modalFormData.fields.find(item => item.name === 'team_name').invisible = false
        modalFormData.fields.find(item => item.name === 'team_name').value = user.team.name
      } else {
        modalFormData.fields.find(item => item.name === 'team_id').invisible = false
        modalFormData.fields.find(item => item.name === 'team_name').invisible = true
        modalFormData.fields.find(item => item.name === 'team_id').dataList = [...this.teams.map(team => {
          return {
            value: team._id,
            label: team.name
          }
        })]
        modalFormData.fields.find(item => item.name === 'team_id').value = user.team ? user.team._id : null
      }
      modalFormData.originalData.team_id = user.team ? user.team._id : null
      modalFormData.originalData.role = user.role
      return modalFormData
    },
    getNewTeamModalForm () {
      let modalFormData = Object.assign({}, this.newTeamModalForm)
      modalFormData.fields.find(item => item.name === 'name').value = ''
      modalFormData.fields.find(item => item.name === 'role').value = 'adops-team'
      modalFormData.hiddenFields.find(item => item.name === 'team_id').value = this.authData.team._id
      modalFormData.fields.find(item => item.name === 'permissions').value = []
      modalFormData.fields.find(item => item.name === 'permissions').dataList = [...this.permissions.map(permission => {
        return {
          value: permission._id,
          label: permission.name,
          group: permission.guard_name
        }
      })]
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').value = []
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').dataList = [...this.accounts.filter(a => a.platform === 1).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').value = []
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').dataList = [...this.accounts.filter(a => a.platform === 2).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]

      return modalFormData
    },
    getEditTeamForm (team) {
      let modalFormData = Object.assign({}, this.editTeamModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = team._id
      modalFormData.fields.find(item => item.name === 'name').value = team.name
      modalFormData.originalData.name = team.name
      modalFormData.fields.find(item => item.name === 'role').value = team.role
      modalFormData.originalData.role = team.role
      modalFormData.hiddenFields.find(item => item.name === 'team_id').value = this.authData.team._id
      modalFormData.fields.find(item => item.name === 'permissions').value = team.permissions.map(item => item._id)
      modalFormData.originalData.permissions = team.permissions.map(item => item._id)
      modalFormData.fields.find(item => item.name === 'permissions').dataList = [...this.permissions.map(permission => {
        return {
          value: permission._id,
          label: permission.name,
          group: permission.guard_name
        }
      })]
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').value = team.facebook_account_ids || []
      modalFormData.originalData.facebook_account_ids = team.facebook_account_ids || []
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').dataList = [...this.accounts.filter(a => a.platform === 1).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').value = team.twitter_account_ids || []
      modalFormData.originalData.twitter_account_ids = team.twitter_account_ids || []
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').dataList = [...this.accounts.filter(a => a.platform === 2).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]

      return modalFormData
    }
  }
}
</script>
<style scoped>
#users-teams-view {
  display: grid;
  flex-direction: column;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
.col-actions {
  width: 60px;
}
.scrollable-cols {
  /* max-width: 650px; */
  min-width: 650px;
}
</style>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "new-user": "New User",
    "new-team": "New Team",
    "edit-user": "Edit User",
    "edit-team": "Edit Team",
    "delete-user": "Delete User",
    "delete-team": "Delete Team"
  },
  "es": {
    "new-user": "Nuevo usuario",
    "new-team": "Nuevo equipo",
    "edit-user": "Editar usuario",
    "edit-team": "Editar equipo",
    "delete-user": "Borrar usuario",
    "delete-team": "Borrar equipo"
  }
}
</i18n>
